import React from 'react';

const CaptchaText = ({ captcha }: { captcha: string }) => {
  return (
    <div className="bg-black/5 font-extrabold border-2 rounded-md px-3 inline-flex tracking-[5px]">
      {captcha.split('').map((char, index) => {
        const rotation = Math.floor(Math.random() * 21) - 10;
        return (
          <span
            key={index}
            className="inline-block text-black"
            style={{ transform: `rotate(${rotation}deg)` }}
          >
            {char}
          </span>
        );
      })}
    </div>
  );
};

export default CaptchaText;